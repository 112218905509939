import GoogleTagManager from 'react-gtm-module';

const GTM_ID = 'GTM-PJXVDWV';
const ENVIRONMENT = 'qa';
const SERVER_FQDN = 'https://mcl-qa.opswat.com';

class GtmService {
    initialize(dataLayer) {
        if (GTM_ID && ENVIRONMENT === 'prod' ) {
            GoogleTagManager.initialize({
                gtmId: GTM_ID,
                dataLayer
            });
        }
    }

    handleNavigation = (url) => {
        const dataLayer = { 'event': 'link', 'url': `${SERVER_FQDN}${url}` };
        GTM_ID && ENVIRONMENT === 'prod' && GoogleTagManager.initialize({
            gtmId: GTM_ID,
            dataLayer
        });
    };
}

export default new GtmService();
