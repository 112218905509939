/* eslint-disable no-useless-escape */
import { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Button, Col, Container, Navbar, Row } from 'react-bootstrap';
import { UserContext } from '@mdc/ui';
import { Authenticator } from '@mdc/services';

import './IpRestriction.scss';

const SSO_ISSUER = 'https://id-qa.opswat.com/';

const IpRestriction = ({ children, t }) => {
    const userContext = useContext(UserContext);

    const dom = useMemo(() => <Container fluid className='ipRestriction'>
        <Container>
            <Navbar className='align-items-center pl-0 pr-0 main'>
                <Navbar.Brand>
                    <a className='logo' href='/'/>
                </Navbar.Brand>
                <Navbar.Collapse className='justify-content-end'>
                    <span className='userName'>{userContext?.data?.firstName}</span>
                    <Button onClick={() => (async () => Authenticator.logout())()}>{t('Logout')}</Button>
                </Navbar.Collapse>
            </Navbar>
            <Col>
                <Row className='firstRow'>
                    <div className='restrictedAccessImg'/>
                </Row>
                <Row>
                    <h1 dangerouslySetInnerHTML={{ __html: t('Access is restricted because your IP is not approved by the organization administrators') }}/>
                </Row>
                <Row>
                    <h5 dangerouslySetInnerHTML={{ __html: t('Please contact your organization administrator for updating the restrictions.<br/>If you are administrator, you can login and reset the IP allowlist <a href=\"{{SSO_ISSUER}}reset-ip-whitelist\">here</a>', { SSO_ISSUER: SSO_ISSUER }) }}/>
                </Row>
            </Col>
        </Container>
    </Container>, [t, userContext]);

    if (userContext && userContext.state === userContext.STATES.IP_RESTRICTION) {
        return dom;
    }

    return children;
};

IpRestriction.propTypes = {
    children: PropTypes.node.isRequired,
    t: PropTypes.func
};

export default withTranslation()(IpRestriction);
