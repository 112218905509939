/* eslint-disable camelcase */
import { createContext, useState, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { UserContext } from '@mdc/ui';

const InAppNotificationContext = createContext(undefined);
const WEBSOCKET_URL = 'wss://notifications-qa.metadefender.opswat.com';

export const InAppNotificationProvider = ({ children }) => {
    const userContext = useContext(UserContext);

    const [notifications, setNotifications] = useState(undefined);

    const { userId, isOrganizationAdmin } = useMemo(() => {
        return {
            userId: userContext?.data?.sso_user_id,
            isOrganizationAdmin: userContext?.isOrganizationAdmin
        };
    }, [userContext]);

    const {
        sendJsonMessage,
        readyState,
        // lastMessage,
        lastJsonMessage
    } = useWebSocket(
        userId && isOrganizationAdmin ? WEBSOCKET_URL + `?id=${encodeURIComponent(userId)}` : null,
        {
            //Will attempt to reconnect on all close events, such as server shutting down
            shouldReconnect: () => true,
            retryOnError: true
        }
    );

    useEffect(() => {
        // Message received form Websocket
        switch (lastJsonMessage?.action) {
            case 'get-notifications':
                // When all notifications are gotten
                if (lastJsonMessage.notifications) {
                    setNotifications(lastJsonMessage.notifications);
                }
                break;
            case 'new-notification':
                // When a new notifications coming
                if (lastJsonMessage.notification) {
                    setNotifications((last) => [lastJsonMessage.notification].concat(last || []));
                }
                break;
            case 'retain':
                // On success retain connect, do nothing
                break;
            default:
                break;
        }
    }, [lastJsonMessage]);

    useEffect(() => {
        let interval;
        if (readyState === ReadyState.OPEN && userId && sendJsonMessage) {
            // Get notifications
            sendJsonMessage({ action: 'get-notifications', userId });
            // Set interval to extend websocket connect session every 1h
            setInterval(() => sendJsonMessage({ action: 'retain' }), 3600000);
        }

        return function () {
            // Clear interval
            clearInterval(interval);
        };
    }, [userId, readyState, sendJsonMessage]);

    return <InAppNotificationContext.Provider value={{ data: notifications }}>
        {children}
    </InAppNotificationContext.Provider>;
};

InAppNotificationProvider.propTypes = {
    children: PropTypes.element.isRequired
};

export default InAppNotificationContext;
